<template>
    <div class="branches admin_page_style branch_show">
        <div v-if="branch" >
            <div class=" d-flex justify-content-between align-items-center p-23 pb-0">
                <h2 class="font-weight-bold ">Информация о филиале</h2>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="dates d-flex align-items-center mr-20"> <h6 class="mb-0">Создано:</h6> <b v-text="formatDate(branch.created_at, true)" /></div>
                    <div class="buttons d-flex ml20">
                        <router-link to="/branches" class="my_otmena mr-20">Назад</router-link>
                        <router-link :to="'/branches/edit/' + branch.id" class="submit_btn mr-20">Изменить</router-link>
                        <button class="submit_btn" @click.prevent="remove(branch.id)">УДАЛИТЬ</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div >
                    <div class=" p-0 d-flex flex-wrap align-items-start" >
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Тип: </span>
                            </div>
                            <input type="text" class=" font_ttf_regular w-100 " :value="branch.type_name" disabled/>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Регион: </span>
                            </div>
                            <input type="text" class=" font_ttf_regular w-100 " :value="branch.region_id ? branch.region.name_uz : ''" disabled/>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Название: </span>
                            </div>
                            <input type="text" class="font_ttf_regular  w-100" :value="branch.name" disabled/>
                        </div>

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">МФО: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  " :value="branch.mfo" disabled/>
                                </div>

                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">ИНН: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  " :value="branch.tin" disabled/>
                                </div>


                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Номер телефона: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  " :value="branch.phone" disabled/>
                                </div>


                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Номер телефона 2: </span>
                                    </div>
                                    <input type="text" class=" font_ttf_regular  w-100  " :value="branch.phone1" disabled/>
                                </div>


                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Email: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  " :value="branch.email" disabled/>
                                </div>


                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Факс: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  " :value="branch.fax" disabled/>
                                </div>



                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Адрес: </span>
                            </div>
                            <input type="text" class="  font_ttf_regular  w-100  " :value="branch.address" disabled/>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Ориентир: </span>
                            </div>
                            <input type="text" class="  font_ttf_regular w-100 " :value="branch.approximately" disabled/>
                        </div>
                    </div>
                    <div  >
                        <div class="card shadow mb-4">
                            <h3 class="card-header font-weight-bold">Реквизиты</h3>
                            <div class="card-body">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">HUMO счет: </span>
                                    </div>
                                    <input type="text" class=" font_ttf_regular  w-100  "
                                           :value="branch.humo_account" disabled/>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">UZCARD счет: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  "
                                           :value="branch.uzcard_account" disabled/>
                                </div>
                            </div>
                        </div>
                        <div class="card shadow">
                            <div
                                class="card-header  d-flex justify-content-between align-items-center">
                                <h3 class="title mb-0 font-weight-bold">Координаты</h3>
                                <button class="submit_btn">Отправить</button>
                            </div>
                            <div class="card-body">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Широта: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  "
                                           :value="branch.coordinate_latitude" disabled/>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Долгота: </span>
                                    </div>
                                    <input type="text" class="  font_ttf_regular  w-100  "
                                           :value="branch.coordinate_longitude" disabled/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card shadow">
            <div class="card-header  d-flex justify-content-between align-items-center">
                <h3 class="title mb-0 font-weight-bold" >Список банкоматов</h3>
                <router-link v-if="branch" :to="'/branches/create?type=4&branch_id='+branch.id" class="submit_btn">Добавить банкомат</router-link>
            </div>
            <div class="card-body">
                <div v-if="branch && branch.atm && branch.atm.length" class="table-responsive">
                    <table class="table table-sm table-bordered table-striped text-center">
                        <thead>
                            <tr class="bg-gray-100 text-dark text-uppercase">
                                <th>Действия</th>
                                <th>#</th>
                                <th>Наименование</th>
                                <th>Тип</th>
                                <th>Номер</th>
                                <th>Статус</th>
                                <th>Адрес</th>
                                <th>Ориентир</th>
                                <th>Координаты</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, k) in branch.atm">
                            <td width="150">
                                <div class="btn-group">
                                    <router-link v-if="branch" :to="`/branches/edit/${branch.id}?type=4&branch_id=`+item.id" class="btn btn-sm btn-info"><CIcon name="cil-pencil" title="Изменить"/></router-link>
                                    <button class="btn btn-sm btn-outline-danger" @click.prevent="remove(item.id)">
                                        <CIcon name="cil-x-circle" title="Удалить"/>
                                    </button>
                                </div>
                            </td>
                            <td>{{ ++k }}</td>
                            <td class="text-left">{{ item.name || '-'}}</td>
                            <td v-text="item.atm_type === 2 ? 'HUMO' : (item.atm_type === 1 ? 'UZCARD' : '-')"></td>
                            <td>{{ item.atm_no || '-' }}</td>
                            <td v-html="item.atm_status === 1 ? '<div class=\'badge badge-success\'>Вкл</div>' : (item.atm_status === 0) ? '<div class=\'badge badge-danger\'>Выкл</div>' : '-'"></td>
                            <td>{{ item.address || '-' }}</td>
                            <td>{{ item.approximately || '-' }}</td>
                            <td style="width: 250px;">Ширина: {{ item.coordinate_latitude || '-' }} | Долгота: {{ item.coordinate_longitude || '-' }} </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <h4 class="font-weight-bold" v-else>Все еще нет банкоматов...</h4>
            </div>
        </div>
        <div class="card shadow">
            <div class="card-header  d-flex justify-content-between align-items-center">
                <h3 class="title mb-0 font-weight-bold">Список центров банковских услуг</h3>
                <router-link v-if="branch" :to="'/branches/create?type=3&branch_id='+branch.id" class="submit_btn">Добавить ЦБУ</router-link>
            </div>
            <div class="card-body">
                <div v-if="branch && branch.bsc && branch.bsc.length" class="table-responsive">
                    <table class="table table-sm table-bordered table-striped text-center">
                        <thead>
                        <tr class=" text-dark text-uppercase">
                            <th>Действия</th>
                            <th>#</th>
                            <th>Наименование</th>
                            <th>Адрес</th>
                            <th>Ориентир</th>
                            <th>Координаты</th>
                            <th>Номер телефона</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, k) in branch.bsc">
                            <td width="150">
                                <div class="btn-group">
                                    <router-link v-if="branch" :to="`/branches/edit/${branch.id}?type=3&branch_id=`+item.id" class="btn btn-sm btn-info"><CIcon name="cil-pencil" title="Изменить"/></router-link>
                                    <button class="btn btn-sm btn-outline-danger" @click.prevent="remove(item.id)">
                                        <CIcon name="cil-x-circle" title="Удалить"/>
                                    </button>
                                </div>
                            </td>
                            <td>{{ ++k }}</td>
                            <td class="text-left">{{ item.name || '-' }}</td>
                            <td>{{ item.address || '-' }}</td>
                            <td>{{ item.approximately || '-' }}</td>
                            <td style="width: 250px;">Ширина: {{ item.coordinate_latitude || '-' }} | Долгота: {{ item.coordinate_longitude || '-' }} </td>
                            <td>{{ item.phone || '-'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <h4 v-else>Все еще нет ЦБУ...</h4>
            </div>
        </div>
    </div>
</template>
<script>
import {del, get} from "../../../helpers/api";
import moment from "moment";

export default {
    name: "BranchShow",
    data() {
        return {
            branch: null,
            is_loading: false
        }
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push('/404');
        }
        this.checkMethod()
    },
    methods: {
        checkMethod() {
            if (this.$route.params.id) {
                this.loadBranch(this.$route.params.id)
            }
        },
        formatDate(date, full = false){
            if (full){
                return moment(date).format('DD.MM.YYYY hh:mm');
            }
            return moment(date).format('DD.MM.YYYY');
        },
        loadBranch(id) {
            get(`/branches/${id}`)
                .then((response) => {
                    if (response.data.success) {
                        this.branch = response.data.data;
                    }
                })
                .catch(e => {
                    this.$router.push('/pages/404')
                })
        },
        remove(id) {
            if (confirm('Вы действительно хотите удалить?'))
                del(`/branches/${id}`)
                    .then((response) => {
                        if (response.data.success) {
                            this.checkMethod()
                            this.flashSuccess('Успешно удален');
                        }
                    })

        },
    }
}
</script>
<style scoped lang="scss">
td, th{
    vertical-align: middle !important;
}
.branch_show {
    .input-group {
        height: 48px;
        margin-right: 20px;
        margin-bottom: 20px;
        .input-group-text {
            width: 230px;
        }
        input {
            padding-left: 15px;
        }
    }
}
</style>
